.button-1 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-2 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-3 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-4 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-5 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-6 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 21px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-7 {
  background-color: #fff; /* Green */
  border: none;
  color: #a40000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  margin: 4px 2px;
  cursor: pointer;
}

.change-number {
  background-color: #fff;
    border: none;
    color: #a40000;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 5px 15px;
    cursor: pointer;
}

.change-number:hover {
  background-color: #a40000;
  color: #fff;
    
}


/* For tablet devices */
@media (max-width: 768px) {
  .button-1 .button-2 .button-3 .button-4 .button-5 .button-6 .button-7{
    padding: 5px 10px; /* Adjust this value as needed */
    font-size: 7px; /* Adjust this value as needed */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .button-7 .button-1 .button-2 .button-3 .button-4 .button-5 .button-6{
    padding: 2px 2px; /* Adjust this value as needed */
    font-size: 6px; /* Adjust this value as needed */
  }
}