.footer {
    background-color: black;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    width: 100px;
  }
  
  .social-media-icons a {
    color: white;
    margin-left: 10px;
  }
  
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
  }

  .copyright {
    font-size: 14px;
  }