.hero3 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .hero3 .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero3 .column .icon {
    font-size: 24px;
  }

  .hero3 .column img {
    width: 100%; /* Adjust this value as needed for desktop view */
  }

  .arrow-2 {
    background-image: url('../images/arrow-2.png');
    background-size: cover;
    background-position: center;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .hero3 {
        flex-direction: column;
    }
    .hero3 .column img {
      width: 95%; /* Adjust this value as needed */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .hero3 .column img {
      width: 95%; /* Adjust this value as needed */
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .hero3 {
        flex-direction: column;
    }
  }