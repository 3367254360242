.carousel {
    background-color: #8c8f8f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px; 
    max-width: 100%; /* Ensures the width doesn't exceed the parent container */
    overflow-x: hidden; /* Hides any content that exceeds the width */
  }
  
  .carousel h1 {
    font-size: 2em; /* Adjust this to make the text larger */
  }
  
  .buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .buttons button {
    padding: 10px 20px; 
    margin: 5px;
    font-size: 1em; 
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .buttons button:hover {
    cursor: pointer;
    background-color: #a40000;
    color: white;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 94px;
  }
  
  .content h1 {
    margin-top: 30px;
    color: white;
  }

  .content h1 span {
    color: #930000;
  }  
  

  /* For tablet devices */
  @media (max-width: 768px) {
    .buttons button {
      padding: 5px 5px; /* Adjust this value as needed */
      font-size: 1em; /* Adjust this value as needed */
    }
    .carousel h1 {
        font-size: 1.5em; /* Adjust this value as needed */
    }
    .arrow.right {
      right: 5px; /* Adjust this value as needed */
    }
    .slider-windows {
      height: 25vh; /* Adjust this value as needed */
    }
    .content {
      flex-direction: row;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .buttons button {
      padding: 5px 5px; /* Adjust this value as needed */
      font-size: 1em; /* Adjust this value as needed */
    }
    .carousel h1 {
        font-size: 1.2em; /* Adjust this value as needed */
    }
    .arrow.right {
      right: 0px; 
    }
    .arrow.left {
      left: 0px; 
    }
    .content {
      flex-direction: column;
    }
    .content h1 {
      margin-top: 0px !important;
      margin-left: 10px !important;
    }
    .buttons {
      padding: 0px !important;
    }
    .arrow-3 {
      display: none !important;
    }
    #request-quote-text {
      width: 72% !important;
    }
    .arrow {
      background: transparent !important;
      top: 60% !important
    }
    .slider-windows {
      height: 25vh !important;
    }
    .slider-doors {
      height: 25vh !important
    }
  }

  /* For iPad Air in landscape mode */
  @media (max-width: 1024px) {
    .content h1 {
      margin-top: 36px;
    }
    .carousel h1 {
      font-size: 1em;
    }
    .slider-windows {
      height: 25vh;
    }
    .slider-doors {
      height: 25vh;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .content h1 {
      margin-top: 36px;
    }
    .carousel h1 {
      font-size: 1em;
    }
    .slider-doors {
      height: 65vh !important;
    }
    .slider-windows {
      height: 65vh !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .content h1 {
      margin-top: 36px;
    }
    .carousel h1 {
      font-size: 1em;
    }
    .slider-doors {
      height: 26vh !important;
    }
    .slider-windows {
      height: 26vh !important;
    }
  }