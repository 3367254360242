.wrapper-1 {
  background-image: url('../images/home-images/snow-garden.jpg');
}

.wrapper-2 {
  background-image: url('../images/home-images/autumn-garden.jpg');
}

.wrapper-3 {
  background-image: url('../images/home-images/autumn-night.jpg');
}

.wrapper-4 {
  background-image: url('../images/home-images/cloudy-night.jpg');
}

.wrapper-5 {
  background-image: url('../images/home-images/dew-autumn.jpg');
}

.wrapper-6 {
  background-image: url('../images/home-images/dew-garden.jpg');
}

.wrapper-7 {
  background-image: url('../images/home-images/early-morning.jpg');
}

.wrapper-8 {
  background-image: url('../images/home-images/evening-garden.jpg');
}

.wrapper-9 {
  background-image: url('../images/home-images/evening-green.jpg');
}

.wrapper-10 {
  background-image: url('../images/home-images/evening.jpg');
}

.wrapper-11 {
  background-image: url('../images/home-images/green-garden.jpg');
}

.wrapper-12 {
  background-image: url('../images/home-images/hurricane-evening.jpg');
}

.wrapper-13 {
  background-image: url('../images/home-images/night-green.jpg');
}

.wrapper-14 {
  background-image: url('../images/home-images/night-snow.jpg');
}

.wrapper-15 {
  background-image: url('../images/home-images/noon-garden.jpg');
}

.wrapper-16 {
  background-image: url('../images/home-images/rainy-autumn.jpg');
}

.wrapper-17 {
  background-image: url('../images/home-images/snow-garden.jpg');
}

.wrapper-18 {
  background-image: url('../images/home-images/rainy-green.jpg');
}


.wrapper {
    /* background-image: url('../images/wrapper-bg.jpg'); */
    background-color: #aeb1b0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 2160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sales-text {
    padding: 15px;
    margin-bottom: -50px;
    font-size: medium;
    color: #dee2e6;
    text-shadow: 0 0 0.5px gray, 0 0 1px gray, 0 0 1.5px gray, 0 0 2px gray, 0 0 2.5px gray, 0 0 3px gray, 0 0 3.5px gray;
  }

  .shop-now {
    display: inline-block;
    padding: 5px 10px; /* Smaller padding for a small button */
    font-size: small; /* Match the font size medium */
    color: #930000; /* Text color */
    background-color: #fff; /* Background color */
    border: 2px solid #930000; /* Border color to match text */
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-shadow: none;
    margin-left: 5px;
  }
  
  .shop-now:hover {
    background-color: #930000; /* Background color on hover */
    color: #fff; /* Text color on hover */
  }
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .wrapper {
      height: 1800px;
    }
    body {
        overflow-x: hidden;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .wrapper {
      height: 1200px;
    }
    body {
        overflow-x: hidden;
    }
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #aeb3b6;
    overflow-x: hidden;
  }
