.hero2 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .hero2 .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero2 .column .icon {
    font-size: 24px;
  }

  .hero2 .column img {
    width: 100%; /* Adjust this value as needed for desktop view */
  }

  h1 {
    color: #930000;
  }

  .arrow-1 {
    background-image: url('../images/arrow-1.png');
    background-size: cover;
    background-position: center;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }

  .learn-more-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    color: #930000; /* Text color */
    background-color: #fff; /* Background color */
    border: 2px solid #930000; /* Border color to match text */
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .learn-more-button:hover {
    background-color: #930000; /* Background color on hover */
    color: #fff; /* Text color on hover */
  }
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .hero2 {
        flex-direction: column-reverse;
    }
    .hero2 .column img {
      width: 100%; /* Adjust this value as needed */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .hero2 .column img {
      width: 100%; /* Adjust this value as needed */
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .hero2 {
        flex-direction: column-reverse;
    }
  }