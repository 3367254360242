.hero1 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 150px;
  }
  
  .dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #930000;
    text-indent: -9999px; /* hide the dot character */ 
    animation: glazing 1s infinite;
  }
  
  .dot.active {
    background-color: black;
  }
  
  .slider {
    /* background-color: rgba(128, 128, 128, 0.5); */
    padding: 10px;
    text-align: center;
    cursor: crosshair;
  }
  
  .navigation-instruction {
    font-size: 12px;
    padding: 0;
  }

  .navigation-instruction p {
    padding: 0;
  }
  

  
  /* For tablet devices */
  @media (max-width: 768px) {
    .hero1 {
      height: 70%; /* Adjust this value as needed */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .hero1 {
      height: 90%; /* Adjust this value as needed */
    }
  }


  
@keyframes glazing {
  0% {
    box-shadow: 0 0 5px #dd708b, 0 0 25px #eb5795, 0 0 50px #f70a1d, 0 0 200px #f8037d;
  }
  100% {
    box-shadow: 0 0 10px #33ff5f, 0 0 35px #33ffbb, 0 0 60px #5233ff, 0 0 250px #8fff33;
  }
}