.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.form {
    width: 100%;
    max-width: 300px;
}

.input {
    text-align: center;
    width: 100%;
}

.styled-input {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }
  
  .styled-input:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
  
  .styled-input:hover {
    background-color: #f8f9fa;
  }