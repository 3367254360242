/* General Styles */
/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  text-align: center;
  margin-bottom: 20px;
}



.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}



/* Tabs Section */
.nav-tabs {
  justify-content: center;
  margin-bottom: 20px;
}

.nav-tabs .nav-item {
  margin: 10px 10px;
}

.nav-tabs .nav-link {
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 10px 20px;
}

.nav-tabs .nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.tab-content-wrapper {
  text-align: center;
}

.tab-content-wrapper img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* Hero Sections */
.hero-section {
  padding: 50px 0;
  text-align: center;
}

.hero-section h2 {
  margin-bottom: 20px;
}

.hero-section p {
  max-width: 800px;
  margin: 0 auto;
}

/* Footer */
footer {
  padding: 20px 0;
  background-color: #343a40;
  color: #fff;
  text-align: center;
}