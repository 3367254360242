.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spaced-button {
  margin-right: 10px; /* Adjust the margin-right to your desired spacing */
}

.form-text {
  color: white !important;
}

.button-container {
  margin-right: -10px; /* Compensate for the margin-right of the last link button */
}

.small-link-button {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: rgb(228, 105, 122);
  color: black;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.small-link-button:hover {
  background-color: white;
  border-color: lavender;
}


.hero-content {
  text-align: center;
  color: white;
}

.hero-content h1 {
  font-size: 3rem;
}

.hero-content p {
  font-size: 1.5rem;
}

.hero-content button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-content button:hover {
  background-color: #0056b3;
}


.responsive-menu {
  position: relative;
  background-color: #282c34;
}

.menu-icon {
  position: absolute;
  top: 20px;
  right: 20px; /* Adjusted to be on the top right corner */
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-items {
  display: none;
}

.menu-items.open {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 60px;
  right: 0px;
  border-radius: 5px;
}

.menu-items a {
  padding: 10px;
  color: white;
  text-decoration: none;
}

/* .menu-items a:hover {
  background-color: #555;
} */

@media (min-width: 768px) {
  .menu-icon {
    display: none;
  }

  .menu-items {
    display: flex;
    position: static;
    flex-direction: row;
  }

  .menu-items.open {
    position: static;
    background-color: transparent;
  }

  .menu-items a {
    padding: 10px 20px;
  }
}
.visitCount {
  color: rgb(195, 195, 212);
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
