.slider-doors {
    position: relative;
    width: 100vw;
    height: 60vh; /* Adjust this to make the slider smaller */
    overflow: hidden;
    box-shadow: 0 8px 10px rgba(255, 255, 255, 0.3);
  }
  
  .images-doors {
    display: flex;
    width: 100%;
  }
  
  .images-doors img {
    width: 25% !important;
    height: auto; /* Adjust this to maintain the aspect ratio */
    object-fit: cover;
  }