.column {
    padding-top: 50px; /* Adjust this value to increase/decrease the top padding */
    padding-bottom: 50px; /* Adjust this value to increase/decrease the bottom padding */
  }

.hero4 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow:hidden;
    padding: 40px 20px 40px 20px; /* Adjust this value to increase/decrease the padding */
  }
  
  .hero4 .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero4 .column .icon {
    font-size: 24px;
  }

  p {
    padding: 20px; /* Adjust this value as needed */
  }



  .hero4 .column:first-child {
    order: 1;
  }
  
  .hero4 .column:last-child {
    order: 2;
  }

  #request-quote-text {
    width: 60%;
    text-align: center;  
    margin-top: 10px;
  }

  #request-quote-text p {
    padding: 6px !important  
  }
/* 
  .left-align {
  text-align: left !important;
  } */

  .arrow-3 {
    background-image: url('../images/arrow-3.png');
    background-size: cover;
    background-position: center;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .hero4 {
      flex-direction: column; /* Stack the columns vertically */
    }
  
    .hero4 .column:first-child {
      order: 2;
    }
  
    .hero4 .column:last-child {
      order: 1;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .hero4 {
      flex-direction: column; /* Stack the columns vertically */
    }
  
    .hero4 .column:first-child {
      order: 2;
    }
  
    .hero4 .column:last-child {
      order: 1;
    }
  }

