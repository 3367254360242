/* .blur {
    filter: blur(5px);
} */

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.fading {
    animation: fadeOut 2s forwards; /* Replace 2s with the duration of your fade-out animation */
}