/* Menu.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

  .logo {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: bold;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .logo.logo1 {
    background-image: url('../images/logo-white.svg');
  }
  
  .logo.logo2 {
    background-image: url('../images/logo-pgt.png');
    width: 86px;
    margin-left: 20px;
  }

  .logo:hover svg {
    fill: #fff;
  }

  .menu {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    color: #5e0f0f;
    width: 100%;
  }
  
  .menu-button {
    display: none;
  }
  
  .menu-items {
    display: flex;
    list-style: none;
  }
  
  .menu-items li {
    margin-left: 10px;
    cursor: pointer;
    font-size:medium;
  }

  .menu-items li:hover {
    color: #7a0404d7; 
  }

  
  
  /* For tablet and mobile devices */
  @media (max-width: 768px) {
    .menu {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .menu-button {
      display: block;
    }
  
    .menu-items {
      display: none;
    }
  
    .menu-items.open {
      display: flex;
      flex-direction: column;
      align-items: center;
      color:white;
    }
  
    .menu-items li {
      margin: 10px 0;
    }

    .logo.logo2 {
      margin-left: 0px;
    }


    @keyframes slideInFromRight {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    
    .menu-items.open {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-right: 0px;
      animation: slideInFromRight 0.5s forwards;
    }
    
  }
  
