.wrapper-new {
    /* background-image: url('../images/wrapper-bg.jpg'); */
    background-color: #aeb1b0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 2160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }